.Services {
  &-Body {
    @include mf(lg) {
      padding-top: 60px;
      position: relative; } }
  &-Heading {
    margin-bottom: 15px;
    @include mf(sl) {
      margin-bottom: 50px; } }
  &-Content {
    position: relative;
    z-index: 2;
    @include mf(sl) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  &-Item {
    margin-bottom: 15px;
    @include mf(sl) {
      width: calc(33.33% - 17px);
      height: 280px;
      margin-bottom: 25px; }
    @include mf(lg) {
      height: 320px; }
    &_big {
      @include mf(sl) {
        width: calc(66.66% - 9px); }
      .ServicesCard-List-Item {
        @include mf(sl) {
          width: 33.33%; }
        &:nth-child(2),
        &:nth-child(3) {
          @include mf(sl) {
            margin-top: 0; } } } }
    &:last-of-type {
      margin-bottom: 0; } }
  &-Card {
    width: 100%;
    height: 100%; }
  &-Simg {
    display: none;
    &_spark {
      @include mf(lg) {
        display: block;
        position: absolute;
        left: -225px;
        bottom: -60px; } } } }

.ServicesCard {
  padding: 20px 10px 25px 0;
  background-color: #f0f0f0;
  border-radius: 3px;
  @include mf(lg) {
    padding: 35px 10px 0 0; }
  &-Title {
    margin-bottom: 15px;
    padding-left: 35px;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    @include mf(sm) {
      font-size: 24px; }
    @include mf(sl) {
      margin-bottom: 40px; }
    @include mf(lg) {
      padding-left: 39px; }
    &:before {
      content: '';
      width: 20px;
      height: 5px;
      background-color: $main-color;
      position: absolute;
      top: 7px;
      left: 0;
      @include mf(sm) {
        top: 11px; }
      @include mf(lg) {
        width: 26px; } }
    .Link {} }
  &-Content {
    padding-left: 17px;
    @include mf(lg) {
      padding-left: 20px; } }
  &-List {
    @include mf(sx) {
      display: flex;
      flex-wrap: wrap; }
    &-Item {
      margin-top: 14px;
      padding-left: 20px;
      letter-spacing: 0.1px;
      position: relative;
      @include mf(sx) {
        width: 50%; }
      @include mf(sm) {
        width: 33.33%; }
      @include mf(sl) {
        width: 100%; }
      &:nth-child(1) {
        margin-top: 0; }
      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: $main-color;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 0; }
      .Link {} } }
  &-Text {
    padding-left: 20px;
    line-height: 1.25;
    letter-spacing: 0.1px; } }
