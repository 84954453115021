.Intro.Intro_ver1 {
  .Intro {
    &-Body {}
    &-Content {}
    &-Heading {
      margin-bottom: 20px; }
    &-Subheading {
      margin-bottom: 20px;
      @include mf(sm) {
        margin-bottom: 20px; }
      @include mf(lg) {
        margin-bottom: 55px; }
      br {
        @include mf(sm) {
          display: none; } } }
    &-Player {
      margin-bottom: 20px;
      @include mf(sm) {
        margin-bottom: 0; } }
    &-Simg {
      &_spark {
        width: 385px;
        bottom: -180px;
        @include mf(lg) {
          width: auto;
          right: -100px;
          bottom: -225px; } }
      &_soldier {
        width: 168px;
        top: -115px;
        right: -15px;
        @include mf(lg) {
          width: auto;
          top: auto;
          right: -195px;
          bottom: -109px;
          z-index: 2; } }
      &_cloud_left {}
      &_cloud_right {} } } }
